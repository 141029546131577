import { gql } from '@apollo/client'
import { contentFragment } from 'Fragments'

const GET_CONTENT = /* GraphQL */ gql`
  query content($arguments: GetContent!) {
    content(arguments: $arguments) {
      _cache
      ...content
    }
  }
  ${contentFragment}
`

export default GET_CONTENT
