import { gql } from '@apollo/client'
import { smallProductFragment } from 'Fragments'
import categoryFragment from './category'

const contentFragment = /* GraphQL */ gql`
  fragment content on Content {
    type
    uuid
    slug
    sponsor
    theme
    created
    state {
      code
    }
    title {
      long
      short
    }
    image {
      main
      cropped
    }
    categories {
      ...categoryFields
    }
    author {
      name
    }
    store {
      id
    }
    flags {
      isFeatured
    }
    layout {
      topproducts {
        data {
          title
          subTitle
          tpproducts {
            uuid
            title
            brand
            price
            media
            affiliateURL
            slug
            theme
          }
        }
        position
      }
      thelook {
        data {
          title
          subTitle
          looks {
            url
            murl
            title
          }
        }
        position
      }
      twinarticles {
        data {
          firstarticle {
            uuid
            title
            description
            theme
            media
            affiliateURL
            slug
          }
          secondarticle {
            uuid
            title
            description
            theme
            media
            affiliateURL
            slug
          }
        }
        position
      }
      banners {
        data{
          media {
            url
            type
            murl
          } 
          text {
            title
            subtitle
          } 
          button{
            text
            url
          } 
          containertype
          theme
        }
        position
      }
      itemmanager {
        data {
          theme
        }
        position
      }
      featureitems {
        data {
          isFeatured
          articles
        }
        position
      }
      brands {
        data {
          title
          image
          mimage
          logo {
            url
            brand
          }
        }
        position
      }
      collections {
        preview {
          products {
            ...smallProduct
          }
        }
        data {
          items
          perRow
        }
        position
      }
      images {
        data {
          link
          credit
          url
        }
        position
      }
      embeds {
        data
        position
      }
      intros {
        data
        position
      }
      lines {
        position
      }
      products {
        preview {
          alternatives {
            ...smallProduct
          }
          product {
            ...smallProduct
          }
        }
        data {
          alternatives {
            enabled
            items
            perRow
          }
          affiliateUrl
          image
          text
          title
          description
          store
          header
          price
          uuid
        }
        position
      }
      subtitles {
        data
        position
      }
      texts {
        data
        position
      }
      buttons {
        data {
          text
          url
        }
        position
      }
      spaces {
        position
      }
      contents {
        position
        data {
          description
          title
          type
          uuids
        }
      }
    }
  }

  ${categoryFragment}
  ${smallProductFragment}
`

export default contentFragment
