import React from 'react'
import { LazyLoadImage } from 'Blocks'
import config from 'Config'
const Banner = props => {
    const loadMobileVideo = () => {
      if(props.isIOS) {
        return (
          <video autoplay="" muted="" playsinline="" data-wf-ignore="true" data-object-fit="cover" width="100%" height="100%" style={{objectFit: 'cover'}}>
            <source src={props.data.data.media.murl} type="video/webm" data-wf-ignore="true" />
            Your browser does not support the video tag.
          </video>
        )
      } else {
        return (
          <video loop autoplay='' muted width="100%" height="100%" style={{objectFit: 'cover'}}>
            <source src={props.data.data.media.murl} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        )
      }

    }
    const LoadBanner = () => {
        if(props.data.data.containertype === 'banner') {
          return (
            <div className='hero-banner' style={{backgroundImage: `URL(${props.screenType === 'small' && props.data.data.media.murl != '' ? props.data.data.media.murl : props.data.data.media.url})`}}>
              <div className="banner-container">
                <div className="banner-content-section">
                  <div className="banner-title">{props.data.data.theme}</div>
                  <div className="banner-heading">{props.data.data.text.title}</div>
                  <div className="banner-header-title">{props.data.data.text.subtitle}</div>
                  <div className="banner-button-row">
                    <a href={props.data.data.button.url} className={`${config.name === 'sb' ? 'primary-button button-white' : 'primary-link'}`}>{props.data.data.button.text}</a>
                  </div>
                </div>
              </div>
            </div>
          )
        } else if (props.data.data.containertype === 'video-banner') {
          return (
            <div className="hero-banner video-header">
              <div className={config.name === 'sb' ? 'hero-banner-text' : 'hero-banner-logo'}>
                <img src={props.data.data.text.title} style={{height: (props.screenType === 'small' ? '75px' : '100px')}} className={config.name === 'sb' ? 'global-image' : 'banner-logo'} />
              </div>
              {props.screenType !== 'small' ? (
                <video className='hero-banner-video' loop autoplay='' muted width="100%" height="100%" style={{objectFit: 'cover'}}>
                  <source src={props.data.data.media.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                ) : loadMobileVideo()
              }
            </div>
          )
        } else if(props.data.data.containertype === 'left-side') {
          return (
            <div className="image-content-section">
              <div className="section-container">
                <div className="section-content-holder">
                  <div className="section-image-block">
                    <img src={props.data.data.media.url} alt="" className="global-image" />
                  </div>
                  <div className="section-content-block">
                    <div className={config.name === 'sb' ? 'content-tag' : 'content-tag-text'}>{props.data.data.theme}</div>
                    <div className="section-title">{props.data.data.text.title}</div>
                    <div className="section-description">{props.data.data.text.subtitle}</div>
                    <div className="section-button-row">
                      <a href={props.data.data.button.url} className="primary-button">{props.data.data.button.text}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        } else if(props.data.data.containertype === 'right-side') {
          return (
            <div className="image-content-section reverse-content">
              <div className="section-container">
                <div className="section-content-holder">
                  <div className="section-image-block">
                    <img src={props.data.data.media.url} alt="" className="global-image" />
                  </div>
                  <div className="section-content-block">
                    <div className={config.name === 'sb' ? 'content-tag' : 'content-tag-text'}>{props.data.data.theme}</div>
                    <div className="section-title">{props.data.data.text.title}</div>
                    <div className="section-description">{props.data.data.text.subtitle}</div>
                    <div className="section-button-row">
                      <a href={props.data.data.button.url} className="primary-button">{props.data.data.button.text}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        } else if(props.data.data.containertype === 'bottom-banner') {
          return (
            <div className="brand-section">
              <div className="brand-content-section">
                <div className="brand-content-image">
                  <img src={props.data.data.text.title} alt="" className="brand-logo" />
                </div>
                <div className="brand-content-link-row">
                  <a href={props.data.data.button.url} className="primary-link">{props.data.data.button.text}</a>
                </div>
              </div>
              <img src={props.data.data.media.url} alt="" className="brand-image" />
            </div>
          )
        }
        return null
    }

    return (
      <div className={props.className}>
        <LoadBanner />
      </div>
    )
}

export default Banner