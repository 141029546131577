import { gql } from '@apollo/client'

const smallProductFragment = /* GraphQL */ gql`
  fragment smallProduct on Product {
    affiliateUrl
    brand
    description
    flags {
      isDiscounted
      isNoindex
      isStocked
    }
    id
    image
    prices {
      currency {
        symbol
        code
      }
      list
      raw
      rrp
    }
    slug
    store {
      id
      name
      slug
      network
    }
    title
    uuid
  }
`

export default smallProductFragment
